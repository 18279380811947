import React from 'react';
import { Redirect } from '@reach/router';

/* export default (props) => {
    return (typeof window !== 'undefined') ? (
      <Helmet>
        <meta http-equiv="refresh" content={`0;url=${window.location.origin}${props.pageContext.redirect}`} />
      </Helmet>
    ) : (
      <Helmet>
        <meta http-equiv="refresh" content={`0;url=${props.pageContext.redirect}`} />
      </Helmet>
    );
}; */

export default (props) => <Redirect to={props.pageContext.redirect} noThrow />;
